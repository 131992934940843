/*
 * @Author: xr
 * @Date: 2022-01-07 09:14:11
 * @LastEditors: xr
 * @LastEditTime: 2022-02-25 16:17:56
 * @FilePath: \postStation-office\src\api\index.js
 */
import request from '../utils/request'

// 获取案例
export const getExample = query => {
  return request({
    url: '/sys/website/register/IndustryList',
    method: 'get',
    params: query
  })
}

// 获取驿站
export const getStation = query => {
  return request({
    url: '/sys/website/register/stationNameList',
    method: 'get',
    params: query
  })
}

// 获取所属行业
export const getIndustry = query => {
  return request({
    url: '/sys/website/register/IndustryList',
    method: 'get',
    params: query
  })
}

// 获取荣誉证书
export const getHonorList = query => {
  return request({
    url: '/sys/website/certificate/getCertificatePicture',
    method: 'get',
    params: query
  })
}

// 获取政策法规
export const policiesAndRegulations = query => {
  return request({
    url: '/sys/website/PoliciesAndRegulationsWebsiteController/queryPageList',
    method: 'get',
    params: query
  })
}

// 获取专家
export const getProfessional = query => {
  return request({
    url: '/sys/website/getExpertNameSex',
    method: 'get',
    params: query
  })
}

// 获取专家ByID
export const getProfessionalById = query => {
  return request({
    url: '/sys/website/getExpertById',
    method: 'get',
    params: query
  })
}

// 获取字典
export const getDict = query => {
  return request({
    url: '/sys/dict/newGetDictItems/' + query,
    method: 'get'
    // params: query
  })
}

// 获取新闻列表
export const journalism = query => {
  return request({
    url: '/sys/website/news/list',
    method: 'get',
    params: query
  })
}

// 获取明星企业图片路径
export const famousEnterprise = query => {
  return request({
    url: '/sys/website/famousEnterprise/getEnterprisePicture',
    method: 'get',
    params: query
  })
}

// 友情链接
export const getLink = query => {
  return request({
    url: '/sys/website/queryAllLinkPicture',
    method: 'get',
    params: query
  })
}

// 查总站信息
export const information = query => {
  return request({
    url: '/sys/website/TerminusInformationController/queryList',
    method: 'get',
    params: query
  })
}

// 查子站介绍
export const introductionToSubStation = query => {
  return request({
    url: '/sys/website/register/stationNameIntroduction',
    method: 'get',
    params: query
  })
}

// 获取行业信息
export const industryInformation = query => {
  return request({
    url: '/sys/website/industryInformation/list',
    method: 'get',
    params: query
  })
}
