/*
 * @Author: xr
 * @Date: 2022-01-06 09:17:47
 * @LastEditors: xr
 * @LastEditTime: 2022-02-18 14:48:27
 * @FilePath: \postStation-office\src\utils\request.js
 */
import axios from 'axios'
import qs from 'qs'

const service = axios.create({
  baseURL: '/station',
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Accept-Language': 'zh-CN,zh,zh-Hans;q=0.8,en-US;q=0.3,en;q=0.2'
  },
  timeout: 5000
})
service.interceptors.request.use(

  config => {
    if (config.method === 'get') {
      config.paramsSerializer = function(query) {
        return qs.stringify(query, { arrayFormat: 'repeat' })
      }
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject()
  }
)

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data
    } else {
      Promise.reject()
    }
  },
  error => {
    console.log(error)
    return Promise.reject()
  }
)

export default service
